
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { MenuService } from 'src/app/services/menu.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';


interface LanguageFlag {
    langcode: string;
    name: string;
    icon?: string;
    id: number;
    active?: boolean
}


@Component({
    selector: 'app-header-style-six',
    templateUrl: './header-style-six.component.html',
    styleUrls: ['./header-style-six.component.scss']
})
export class HeaderStyleSixComponent implements OnInit {
    photoUrl: string = environment.serverUrl;
    isSticky: boolean = false;
    language: LanguageFlag;
    languages: LanguageFlag[] = [];
    menus: any;
    topmenus: any;
    submenus: any;
    telephone: any;
    email: any;
    instagram: any;
    youtube: any;
    facebook: any;
    isLoading: boolean = true;
    funeralEmail: string;
    twitter: string;
    logoUrl = environment.logoUrl


    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private languageService: LanguageService, private menuService: MenuService, private appSettingService: AppSettingService, private translationService: TranslationService) { }

    ngOnInit() {
        this.detectHeader()
        // this.getCountry();
        this.getAllFrontMenus();
        this.getAllLanguages();
        this.getAppSettings();



    }

    getAllLanguages() {
        this.languageService.getAllLanguage().subscribe((data: any) => {
            this.languages = data;
            this.setDefaultLanguage();

        })
    }

    setDefaultLanguage() {
        const lang = this.translationService.getSelectedLanguage();

        if (lang)
            this.setLanguageWithRefresh(lang);
    }

    setLanguageWithRefresh(lang: string) {
        this.languages.forEach((language: LanguageFlag) => {
            if (language.langcode === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });

        this.translationService.setLanguage(lang);
    }

    setNewLanguageRefresh(lang: string) {
        this.translationService.setLanguage(lang).subscribe((response) => {
            this.setLanguageWithRefresh(response['LANGUAGE']);
            window.location.reload();
        });

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }


    getAllFrontMenus() {
        this.menuService.getAllFrontMenuItem().subscribe((data: any) => {
            this.topmenus = data.data
                .filter(x => x.parentId === null && x.isActive)
                .sort((a, b) => a.order - b.order)
                .map(element => ({
                    ...element,
                    inverseParent: element.inverseParent?.filter(x => x.isActive).sort((a, b) => a.order - b.order)
                }));


            if (this.topmenus) {
                this.isLoading = false;
            }
        });



    }


    getAppSettings() {
        this.appSettingService.getAppSetting().subscribe((data: any) => {
            this.telephone = data.find(item => item.key === "telefon")?.value;
            this.email = data.find(item => item.key === "email")?.value;
            this.instagram = data.find(item => item.key === "instagram")?.value;
            this.youtube = data.find(item => item.key === "youtube")?.value;
            this.facebook = data.find(item => item.key === "facebook")?.value;
            this.funeralEmail = data.find(item => item.key === "cenazemail")?.value;
            this.twitter = data.find(item => item.key === "twitter")?.value;
        })
    }

    windowScrolled: boolean | undefined;

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.windowScrolled = window.scrollY > 100;
    }
    // Navigation
    navMethod: boolean = false;
    toggleNav() {
        this.navMethod = !this.navMethod;
    }
    // Search
    searchMethod: boolean = false;
    toggleSearch() {
        this.searchMethod = !this.searchMethod;
    }
    // Mobile 
    open: boolean = false;
    trigger(item: { open: boolean; }) {
      item.open = !item.open;
    }
    // Add class on resize and onload window
    visible: boolean = false;
    breakpoint: number = 991;
    public innerWidth: any;
    detectHeader() {
        this.innerWidth = window.innerWidth;
        this.visible = this.innerWidth >= this.breakpoint;
    }

}