<app-banner [data]="detail.contentTypeId== 28 ? {content: 'MEMORIES' | translate, title: detail.title , link:'/anilar'} : {content: 'ANNOUNCEMENT_LITTLE' | translate, title: detail.title}"></app-banner>


<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc">
                    <div class="news-title">
                        <!-- <h4>{{detail.title}}</h4> -->
                    </div>
                    &nbsp;
                    <ng-container *ngIf="detail?.contentImages.length>0">
                        <swiper-container class="big-photo"
                            appSwiper #swiper thumbs-swiper=".slider-preview" navigation="true" autoplay="true">
                            <swiper-slide *ngFor="let item of detail?.contentImages">
                                <div class="ratio ratio-4x3"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') no-repeat center center / contain'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                        <swiper-container class="slider-preview"
                            appSwiper #swiperThumbs  space-between="10" slides-per-view="7"
                            *ngIf="detail?.contentImages.length > 1" navigation="true">
                            <swiper-slide *ngFor="let item of detail?.contentImages">
                                <div class="ratio ratio-4x3" role="button"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') center / cover'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </ng-container>
                    <div class="entry-meta mb-30 mt-30">
                        <ul>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>{{'NUMBER_OF_VIEWS'|translate}}</span>
                                <a>{{detail?.viewCount ? detail?.viewCount :'NOT_VIEW_YET'|translate}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>{{'LAST_UPDATE'|translate}}</span>
                                <a>{{ detail?.modifiedDate ? (detail?.modifiedDate|date:'dd/MM/yyyy - hh:mm') :
                                    (detail?.creationDate
                                    |date:'dd/MM/yyyy - hh:mm')}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="container">
                        <div class="article-content">
                            <p id="dynamic-value"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>