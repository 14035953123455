import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { SmallItemService } from '../../../services/smallItem.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    smallSlider: any;
    photoUrl: string = environment.serverUrl

    constructor(private smallItemService: SmallItemService) { }

    ngOnInit(): void {
        this.getSmallSlider();
    }


    getSmallSlider() {
        this.smallItemService.getAllSmallSlider().subscribe((data: any) => {
            this.smallSlider = data.data.filter(slider => slider.isActive === true && slider.isDeleted === false).sort((a, b) => a.order - b.order);

        })
    }


    partnerSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: false,
        margin: 0,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 3
            },
            276: {
                items: 3
            },
            576: {
                items: 5
            },
            768: {
                items: 7
            },
            1200: {
                items: 10
            }
        }
    }


}
