<app-banner [data]="{content: name, title: name }"></app-banner>

<div class="container events education pt-100 pb-100">
    <div class="container publish mtb-30">
        <div class="title-search">
            <!-- <h4>{{name}}</h4> -->
            <div class="search-bar">
                <svg class="svg-icon search-icon" aria-labelledby="title desc" width="18" height="18" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7">
                    <g class="search-path" fill="none" stroke="#848F91">
                        <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                        <circle cx="8" cy="8" r="7" />
                    </g>
                </svg>
                <input type="text" placeholder="{{'SEARCH'|translate}}" (input)="onChange($event)">
            </div>
        </div>
        <div class="row">
            <div class="col-sm " *ngFor="let item of publication">
                <app-publish-card [item]="item"></app-publish-card>
            </div>
        </div>
        <ng-container *ngIf="totalCount === 0">
            <div class="row">
                <div class="no-data col-sm-12 ml-4 mt-3">
                    <label class="font-weight-bold"> <img src="assets/img/no-data.png" id="no-data" alt="">
                        {{'NO_DATA_FOUND' | translate}}</label>
                </div>
            </div>
        </ng-container>
        <mat-paginator [length]="totalCount" [pageSize]="pageSize" [hidePageSize]="'true'" (page)="onPageChange($event)"
            [showFirstLastButtons]="true"></mat-paginator>

    </div>

</div>