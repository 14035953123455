<nav class="navbar navbar-expand-lg navbar-light bg-light pt-200">
    <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <div class="selector">
                    <div class="left"></div>
                    <div class="right"></div>
                    <li class="nav-item">
                        <a class="nav-link" href="#"><i
                                class="fa-solid fa-laptop"></i>{{'ONLINE_DONATION'|translate}}</a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</nav>
<div class="container">
    <div class="navbarCustomList shadow-lg bg-white" id="navbarCostumList">
        <ul class="nav nav-tabs">
            <li class="nav-item text-center" *ngFor="let item of donateCategory">

                <a class="na-link" data-bs-toggle="tab" role="tab" aria-selected="false" href="#">{{item.title}}</a>
            </li>
            <li class="nav-item text-center">
                <a class="na-link" data-bs-toggle="tab" role="tab" aria-selected="false" href="#">{{'FUNERAL_FUND'
                    |translate}}</a>
            </li>
        </ul>
    </div>
</div>
<div class="container">
    <div class="donation-detail-card">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6"
                     >
                    <div class="single-motivation-courses-item mb-30 cf">
                        <div class="courses-image">
                            <a class="d-block"><img src="assets/imgs/cenazeFonu.jpg" alt="image"></a>

                        </div>
                        <div class="courses-content">
                            <h3><a class="d-inline-block" style="color:#000;">{{'FUNERAL_FUND' |translate}}</a></h3>
                            <p>{{'DITIB_STRASBOURG'|translate}}</p>
                            <!-- 
                            <div class="courses-box-footer">
                                <div *ngIf="smsShow==false">
                                    <div class="input-group-prepend">
                                        <label class="form-control text-center fs-5" style="background-color:#e9ecef"><i
                                                class="fa-solid fa-hashtag"></i></label>
                                    </div>
                                    <div class="warning-group">
                                        <input [(ngModel)]="uyeNo" placeholder="{{'MEMBER_NO'|translate}}"
                                            class="form-control fs-5" min="3" />
                                        <div class="warning">({{'EXAMPLE'|translate}} {{'MEMBER_NO'|translate}}: ST-000)
                                        </div>
                                    </div>


                                </div>
                                <div *ngIf="smsShow==true" class="input-group mb-3">
                                    
                                    <input placeholder="{{'ENTER_CODE'|translate}}" [(ngModel)]="smsInput" type="number"
                                        class="form-control required fs-5" min="1" max="1000000" />

                                </div>
                                <button *ngIf="smsShow==true" (click)="navigateOdeme()"
                                    class="btn btn-primary btn-bagis"> <i class="fa-solid fa-hand-holding-heart"></i>
                                    {{'PAY'|translate}}</button>

                                <button *ngIf="smsShow==false" (click)="sendUyeNo()" class="btn btn-primary btn-bagis">
                                    {{'SEND'|translate}}</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="category" [ngStyle]="show == false? {'display': 'block'} : {'display': 'none'}">

        <app-odeme [category]="category"></app-odeme>

    </div> -->
</div>