
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Content } from 'src/app/models/contentPage';



@Injectable({
    providedIn: 'root'
})
export class ContentService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl
    languageCode: string = '';
    sliderId = 9;
    memoriesId = 28;

    constructor(private baseService: BaseService, private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    
    getAllContentResource(base, contentTypeId?, search?, creationDate?, skip?, pageSize?): Observable<any> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${contentTypeId}&SearchQuery=${search}&CreationDate=${creationDate}&Skip=${skip}&PageSize=${pageSize}`;
        return this.http.get<any>(url);
    }


    getAllSlider(): Observable<any[]> {
        const url = `GetAllContentResource?LanguageCode=tr&ContentTypeId=${this.sliderId}`;
        return this.baseService.baseData(url);
    }

    getAllMemories(): Observable<Content> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.memoriesId}`;
        return this.http.get<Content>(url);
    }

    getContentById(id, base): Observable<Content> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetContentById?Id=${id}`
        return this.http.get<Content>(url);
    }

    getAllTabContent(): Observable<Content> {
        const url = this.path + `GetAllTabContent?LanguageCode=${this.languageCode}`;
        return this.http.get<Content>(url);
    }

    // ~~~~~~~~~~~~~~~~~~ Content General Services ~~~~~~~~~~~~~~~~~~~~~ //

    getBaseContentResourceData(path, contentTypeId?, search?, creationDate?, skip?, pageSize?): Observable<any[]> {
        const url = path + `?LanguageCode=${this.languageCode}&ContentTypeId=${contentTypeId}&SearchQuery=${search}&CreationDate=${creationDate}&Skip=${skip}&PageSize=${pageSize}`;
        return this.baseService.baseData(url);
    }


    getBaseContentData(path: string, contentTypeId?): Observable<any[]> {
        const url = path + `?LanguageCode=${this.languageCode}&ContentTypeId=${contentTypeId}`;
        return this.baseService.baseData(url);
    }


    
}