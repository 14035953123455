<app-banner [data]="{content: 'BUG'|translate, title: 'BUG_404'|translate }"></app-banner>

<head>
    <meta name="robots" content="noindex">
    <meta name="googlebot" content="noindex">
</head>
<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>Oops!</h1>
            <h3>{{'BUG_404_PAGE_NOT_FOUND' | translate}}</h3>
            <p>{{'THE_PAGE_YOU_ARE_LOOKING_FOR_MAY_HAVE_BEEN_REMOVED_ITS_NAME_CHANGED_OR_IS_TEMPORARILY_UNAVAILABLE' |
                translate}}</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span
                    class="label">{{'RETURN_HOME' | translate}}</span><i
                    class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>