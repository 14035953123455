
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Education } from 'src/app/models/education';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';



@Injectable({
    providedIn: 'root'
})
export class EducationService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl
    languageCode: string = '';

    constructor(private http: HttpClient, private baseService: BaseService) {
        this.languageCode = localStorage.getItem('language');
    }


    getEducationById(id, base): Observable<Education> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetEducation?Id=${id}`;
        return this.http.get<Education>(url);
    }

    // ~~~~~~~~~~~~~~~~~~ Education - Event  General Services ~~~~~~~~~~~~~~~~~~~~~ //

    getBaseResourceData(path: string, search: string, startDate?: string, endDate?: string): Observable<any[]> {
        const url = path + `?LanguageCode=${this.languageCode}&SearchQuery=${search}&StartDate=${startDate}&EndDate=${endDate}`;
        return this.baseService.baseData(url);
    }

    getBaseData(path: string): Observable<any[]> {
        const url = path + `?LanguageCode=${this.languageCode}`
        return this.baseService.baseData(url);
    }
}
