import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent {
  event: any;
  photoUrl = environment.serverUrl;
  basePhotoUrl = environment.baseServerUrl;
  
  constructor(private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.activeRoute.data.subscribe(
      (data: { data: any }) => {
        if (data.data) {
          this.event = data?.data?.data;
          const editorContainer = document.getElementById("dynamic-value"); 
          const shadow = editorContainer.attachShadow({ mode: "closed" });
          const content = document.createElement("div");
          content.innerHTML = this.event.description; 
          shadow.appendChild(content);
        }
      });
  }


  missionSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }
}
