<a [routerLink]="item.isShare == true ? ['/egitim-detay', item.id,'true'] : ['/egitim-detay', item.id,'false']">
    <div class="single-courses-box mb-30">
        <div class="education-city">
            {{item?.departmentName}}
        </div>
        <div class="courses-image ">
            <a class="d-block" *ngIf="item?.thumbnail!=null">
                <img [src]="item?.isShare == true ? (basePhotoUrl + item?.thumbnail) : (photoUrl + item?.thumbnail)"
                    alt="image">
            </a>
            <a class="d-block" *ngIf="item?.thumbnail==null">
                <img [src]="logoUrl" alt="image">
            </a>
        </div>

        <div class="courses-content">
            <p><a class="d-inline-block"> {{item.name}}</a>
            </p>
            <div class="events-info-box row">
                <div style="margin-bottom: 13px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <g clip-path="url(#clip0_1_3706)">
                            <path
                                d="M14.2413 12.3544L11.3133 10.1584V5.68517C11.3133 5.23539 10.9497 4.87183 10.5 4.87183C10.0502 4.87183 9.68661 5.23539 9.68661 5.68517V10.5651C9.68661 10.8213 9.807 11.0629 10.012 11.2158L13.2652 13.6558C13.4116 13.7656 13.5824 13.8184 13.7524 13.8184C14.0005 13.8184 14.2445 13.707 14.4039 13.4923C14.674 13.1336 14.6008 12.6236 14.2413 12.3544Z"
                                fill="black" />
                            <path
                                d="M10.5 0C4.70995 0 0 4.70995 0 10.5C0 16.2901 4.70995 21 10.5 21C16.2901 21 21 16.2901 21 10.5C21 4.70995 16.2901 0 10.5 0ZM10.5 19.3734C5.60786 19.3734 1.62664 15.3921 1.62664 10.5C1.62664 5.60786 5.60786 1.62664 10.5 1.62664C15.393 1.62664 19.3734 5.60786 19.3734 10.5C19.3734 15.3921 15.3921 19.3734 10.5 19.3734Z"
                                fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_3706">
                                <rect width="21" height="21" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    &nbsp;&nbsp;{{'START_DATE'|translate}}:
                    {{item.startDate| date:'dd.MM.yyyy'}}
                </div>
                <div style="margin-bottom: 13px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <g clip-path="url(#clip0_1_3706)">
                            <path
                                d="M14.2413 12.3544L11.3133 10.1584V5.68517C11.3133 5.23539 10.9497 4.87183 10.5 4.87183C10.0502 4.87183 9.68661 5.23539 9.68661 5.68517V10.5651C9.68661 10.8213 9.807 11.0629 10.012 11.2158L13.2652 13.6558C13.4116 13.7656 13.5824 13.8184 13.7524 13.8184C14.0005 13.8184 14.2445 13.707 14.4039 13.4923C14.674 13.1336 14.6008 12.6236 14.2413 12.3544Z"
                                fill="black" />
                            <path
                                d="M10.5 0C4.70995 0 0 4.70995 0 10.5C0 16.2901 4.70995 21 10.5 21C16.2901 21 21 16.2901 21 10.5C21 4.70995 16.2901 0 10.5 0ZM10.5 19.3734C5.60786 19.3734 1.62664 15.3921 1.62664 10.5C1.62664 5.60786 5.60786 1.62664 10.5 1.62664C15.393 1.62664 19.3734 5.60786 19.3734 10.5C19.3734 15.3921 15.3921 19.3734 10.5 19.3734Z"
                                fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_3706">
                                <rect width="21" height="21" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    &nbsp;&nbsp;{{'END_DATE'|translate}}:
                    {{item.endDate| date:'dd.MM.yyyy'}}
                </div>
                <div style="margin-bottom: 13px" *ngIf="item.endAppealDate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <g clip-path="url(#clip0_1_3706)">
                            <path
                                d="M14.2413 12.3544L11.3133 10.1584V5.68517C11.3133 5.23539 10.9497 4.87183 10.5 4.87183C10.0502 4.87183 9.68661 5.23539 9.68661 5.68517V10.5651C9.68661 10.8213 9.807 11.0629 10.012 11.2158L13.2652 13.6558C13.4116 13.7656 13.5824 13.8184 13.7524 13.8184C14.0005 13.8184 14.2445 13.707 14.4039 13.4923C14.674 13.1336 14.6008 12.6236 14.2413 12.3544Z"
                                fill="black" />
                            <path
                                d="M10.5 0C4.70995 0 0 4.70995 0 10.5C0 16.2901 4.70995 21 10.5 21C16.2901 21 21 16.2901 21 10.5C21 4.70995 16.2901 0 10.5 0ZM10.5 19.3734C5.60786 19.3734 1.62664 15.3921 1.62664 10.5C1.62664 5.60786 5.60786 1.62664 10.5 1.62664C15.393 1.62664 19.3734 5.60786 19.3734 10.5C19.3734 15.3921 15.3921 19.3734 10.5 19.3734Z"
                                fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_3706">
                                <rect width="21" height="21" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    &nbsp;&nbsp;{{'END_APPEAL_DATE'|translate}}:
                    {{item.endAppealDate| date:'dd.MM.yyyy'}}
                </div>
                <div *ngIf="item?.address">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path
                            d="M10.5 0C6.30635 0 2.89459 3.41176 2.89459 7.60536C2.89459 12.8098 9.70068 20.4501 9.99046 20.7728C10.2626 21.076 10.7378 21.0754 11.0095 20.7728C11.2993 20.4501 18.1054 12.8098 18.1054 7.60536C18.1053 3.41176 14.6936 0 10.5 0ZM10.5 11.4318C8.39007 11.4318 6.67357 9.71529 6.67357 7.60536C6.67357 5.49544 8.39011 3.77893 10.5 3.77893C12.6099 3.77893 14.3264 5.49548 14.3264 7.6054C14.3264 9.71533 12.6099 11.4318 10.5 11.4318Z"
                            fill="black" />
                    </svg>
                    &nbsp;&nbsp;{{item?.address}}
                </div>
            </div>
            <!-- <p class="new-contents">
            {{item.name}}
        </p> -->



        </div>
        <div class="type-info-area">
            <div class="type-info-box">
                <div *ngIf="item.price > 0">{{item.price}} €</div>
                <div *ngIf="item.price===0">{{'FREE'|translate}} </div>
            </div>
            <div class="type-info-box">
                {{item?.educationTypeName}}
            </div>
        </div>

    </div>
</a>