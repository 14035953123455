
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartmentUsers } from 'src/app/models/department';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class DepartmentService {
    path = environment.apiUrl

    constructor(private http: HttpClient) { }


    getAllDepartmentsById(id): Observable<any> {
        const url = this.path + `GetAllDepartments?departmentTypeId=${id}`;
        return this.http.get<any>(url)

    }

    getAllDepartmentsUsers(): Observable<DepartmentUsers[]> {
        const url = this.path + `GetAllDepartmentTypeUsers`;
        return this.http.get<DepartmentUsers[]>(url)

    }

    getDepartmentUserById(id): Observable<DepartmentUsers[]> {
        const url = this.path + `GetDepartmentUser?Id=${id}`;
        return this.http.get<DepartmentUsers[]>(url)

    }

}