<li>
    <ul>
        <li *ngFor="let submenu of menu?.inverseParent">
            <a href="javascript:void(0)"
                [href]="submenu?.link ? submenu?.link : (submenu?.frontPageId ? submenu.slug+ '/'+ submenu.frontPageId : (submenu?.slug ? submenu?.slug : '') )"
                [target]="submenu?.link ? '_blank' : ''">

                {{ submenu.name }}

            </a>
            <span class="dd-trigger" *ngIf="submenu?.inverseParent?.length > 0" (click)="trigger(submenu)">
                <i class="fal" [ngClass]="submenu.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
            </span>
            <app-submenu *ngIf="submenu.inverseParent.length>0 " class="submenu has-submenu"
                [ngClass]="submenu.open ? 'd-block' :  ''" [menu]="submenu"></app-submenu>

        </li>
    </ul>
</li>