<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">{{'HOME'|translate}}</a></li>
                <li *ngIf="data?.link"><a [href]="data?.link">{{data?.content}}</a></li>
                <li *ngIf="!data?.link">{{data?.content}}</li>
            </ul>
            <h2>{{data?.title}}</h2>
        </div>
    </div>
</div>