import { Component } from '@angular/core';


@Component({
  selector: 'app-online-donate',
  templateUrl: './online-donate.component.html',
  styleUrls: ['./online-donate.component.scss']
})
export class OnlineDonateComponent {
  donateCategory = [
  { title: "Kurban" },
  { title: "Fitre" },
  { title: "Zekat" },
  { title: "Genel Bağış" },
  
]
}
