
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from 'src/app/models/appsetting';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppSettingService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getAppSetting(): Observable<AppSetting> {
        const url = this.path + `AppSetting`;
        return this.http.get<AppSetting>(url);
    }
}