import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Content } from 'src/app/models/contentPage';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-homepage-memories',
  templateUrl: './homepage-memories.component.html',
  styleUrls: ['./homepage-memories.component.scss']
})
export class HomepageMemoriesComponent {
  memories: Content[] = [];

  constructor(private contentService: ContentService) { }
  ngOnInit(): void {
    this.contentService.getAllMemories().subscribe((data: any) => {
      this.memories = data.data.filter(memories => memories.isActive === true).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).slice(0, 12);
    })
  }

  memoriesSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  }


}
