import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { PublicationService } from '../../../services/publication.service';

@Component({
  selector: 'app-publish-page',
  templateUrl: './publish-page.component.html',
  styleUrls: ['./publish-page.component.scss']
})
export class PublishPageComponent implements OnInit {
  publication: any[] = [];
  photoUrl = environment.serverUrl;
  basePhotoUrl: string = environment.baseServerUrl;

  constructor(private publicationService: PublicationService) { }


  ngOnInit(): void {
    this.getPublicationByCategory();
  }

  getPublicationByCategory() {
    this.publicationService.getBasePublicationType().subscribe((data: any) => {
      this.publication = data.filter(data => data.publications.length > 0).slice(0,15);
    })
  }

  carouselPublishSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      370: {
        items: 2
      },
      500: {
        items: 3
      },
      768: {
        items: 4
      },
      900: {
        items: 5
      },
      1200: {
        items: 7
      }
    }
  }
}
