<app-home-page-eleven></app-home-page-eleven>

<app-home></app-home>

<div class="contact-info-area pt-90 pb-20d">
    <div class="contact-info-area mt-30 pb-70">
        <div class="courses-area mt-30 pb-30">
            <div class="container " *ngIf="isSmallAnnouncement">
                <owl-carousel-o [options]="carouselSmallAnnouncementSlide">
                    <ng-template carouselSlide *ngFor="let item of smallAnnouncement">
                        <div class="announcement-box">
                            <div id="announcementContent">
                                <div class="title">
                                    {{'ANNOUNCEMENT'|translate}}
                                </div>
                                <p [innerHTML]="item?.description"> </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>


            <app-homepage-news></app-homepage-news>

            <div class="container tabs-area mt-30 pb-70 " *ngIf="tabs?.length>0 || specialSermon">
                <div class="row">
                    <div class="col-md" *ngIf="tabs?.length>0">
                        <div class="worko-tabs ">
                            <mat-tab-group>
                                <div class="tab-group">
                                    <mat-tab class="tab" *ngFor="let tab of tabs; let i=index;" [label]="tab?.title">
                                        <ng-container *ngIf="tab.contents.length>0">
                                            <div class="announcement-list" *ngFor="let detail of tab?.contents">
                                                <a [routerLink]="['sekme-detay',detail.id]">
                                                    <p>{{detail.title}}</p>
                                                </a>
                                                <div class="announcement-footer">
                                                    <a [routerLink]="['sekme-detay',detail.id]">{{'DETAIL'|translate}}
                                                    </a>
                                                    {{detail?.creationDate|date:'dd.MM.yyyy'}}
                                                </div>

                                            </div>
                                        </ng-container>

                                        <div class="no-data" *ngIf="tab?.contents?.length===0">
                                            <img src="assets/img/no-data.png" alt="">{{'NO_DATA_FOUND'|
                                            translate}}
                                        </div>


                                    </mat-tab>
                                </div>


                            </mat-tab-group>
                        </div>
                    </div>


                    <div class="col-sm" *ngIf=" specialSermon">
                        <div class="sermon-area">
                            <h4>{{specialSermon?.name}}</h4>
                            <div class="sermon-box">
                                <div class="sermon-header">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50"
                                        height="50" viewBox="0 0 25 25" style="enable-background:new 0 0 25 25;"
                                        xml:space="preserve">
                                        <style type="text/css">
                                            .st0 {
                                                fill: none;
                                            }

                                            .st1 {
                                                fill-rule: evenodd;
                                                clip-rule: evenodd;
                                                fill: #ED2939;
                                            }
                                        </style>
                                        <g transform="matrix(1,0,0,1,-336,-341)">
                                            <g transform="matrix(2,0,0,2,336,341)">
                                                <rect class="st0" width="12.5" height="12.5" />
                                                <g transform="matrix(0.5,0,0,0.5,-418.5,-170.5)">
                                                    <path class="st1" d="M859.7,362.1v-5.9c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.2l-3.1-1.1v-0.7c0-0.2-0.2-0.4-0.4-0.4
                                               s-0.4,0.2-0.4,0.4v0.4l-3.1-1.1v-0.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.3l-2.7-0.9v-0.5c0-0.2-0.2-0.4-0.4-0.4
                                               s-0.4,0.2-0.4,0.4v2l-1.2-0.4v-1.3c0-0.2-0.2-0.4-0.4-0.4h-0.4v-3.9h0.4c0.2,0,0.4-0.2,0.4-0.4v-1.6c0-0.2-0.2-0.4-0.4-0.4h-0.4
                                               c0-0.9-0.3-1.5-0.6-2c-0.4-0.6-1.1-1-1.9-1.4c-0.1-0.1-0.2-0.1-0.3,0c-0.8,0.4-1.5,0.9-1.9,1.4c-0.4,0.5-0.6,1.2-0.6,2h-0.4
                                               c-0.2,0-0.4,0.2-0.4,0.4v1.6c0,0.2,0.2,0.4,0.4,0.4h0.4v3.9h-0.4c-0.2,0-0.4,0.2-0.4,0.4v9.4H839c-0.2,0-0.4,0.2-0.4,0.4v1.6
                                               c0,0.2,0.2,0.4,0.4,0.4H860c0.2,0,0.4-0.2,0.4-0.4v-1.6c0-0.2-0.2-0.4-0.4-0.4H859.7z M839.3,362.9h20.3v0.8h-20.3V362.9z
                                                M845.9,354.7h-5.8v7.4h18.8v-3.2L845.9,354.7z M840.9,361.3c0,0.2,0.2,0.4,0.4,0.4h3.9c0.2,0,0.4-0.2,0.4-0.4V357
                                               c0-0.2-0.1-0.3-0.3-0.4c-0.6-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.5-0.6-1-0.7c-0.1,0-0.2,0-0.2,0c-0.6,0.2-0.8,0.5-1,0.7
                                               c-0.2,0.3-0.3,0.6-0.9,0.8c-0.2,0.1-0.3,0.2-0.3,0.4V361.3z M846.9,355.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3v5.5
                                               c0,0.2,0.2,0.4,0.4,0.4h10.9c0.2,0,0.4-0.2,0.4-0.4v-2c0-0.2-0.1-0.3-0.3-0.4L846.9,355.5z M841.7,360.9v-3.6
                                               c0.4-0.2,0.6-0.4,0.8-0.6c0.2-0.3,0.3-0.6,0.8-0.8c0.5,0.2,0.6,0.5,0.8,0.8c0.2,0.2,0.4,0.5,0.8,0.6v3.6H841.7z M847.2,356.4
                                               l10.2,3.3v1.3h-10.2V356.4z M855.8,357C855.8,357,855.8,357,855.8,357L855.8,357l3.1,1v-0.7l-3.1-1.1V357z M851.8,355.8l3.1,1
                                               v-0.8l-3.1-1.1V355.8z M848.3,354.6l2.7,0.9v-0.9l-2.7-0.9V354.6z M840.1,353.1h5.5v0.8h-5.5V353.1z M843.2,348.4h-0.8v0.4
                                               c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h-0.4v2.3h3.9V350h-0.4c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V348.4z
                                                M844.8,349.2v-0.8H844v0.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1H844.8z M841.7,348.4h-0.8v0.8h0.4c0.1,0,0.2,0,0.3-0.1
                                               c0.1-0.1,0.1-0.2,0.1-0.3V348.4z M840.1,346.9h5.5v0.8h-5.5V346.9z M844.8,346.1c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.5-0.8-0.8-1.4-1.1
                                               c-0.6,0.3-1.1,0.7-1.4,1.1c-0.3,0.4-0.5,0.9-0.5,1.6H844.8z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>


                                    <!-- {{specialSermon?.content?.creationDate|date:'dd.MM.yyyy'}} -->
                                </div>
                                <div class="sermon-subject">
                                    {{specialSermon?.content?.title}}
                                </div>


                                <div class="type-info-area">
                                    <div class="row">
                                        <a class="router-button" href="/hutbeler">
                                            <button class="view-all-contents-button">
                                                {{'SEE_ALL' | translate}}
                                            </button>
                                        </a>

                                        <div class=" row justify-content-center">
                                            <div class="custom-select-box ">
                                                <div class="dropdown language-switcher d-inline-block"
                                                    [class.active]="classApplied2">
                                                    <button class="dropdown-toggle" type="button"
                                                        (click)="toggleClass2()">
                                                        <span>{{'DOWNLOAD_OPTIONS'|translate}} <i
                                                                class='bx bx-chevron-down'></i></span>
                                                    </button>
                                                    <div class="dropdown-menu">

                                                        <a *ngIf="specialSermon?.content?.folderPath"
                                                            href="{{photoUrl+specialSermon?.content?.folderPath}}"
                                                            download class="dropdown-item d-flex align-items-center">

                                                            <span>{{'PDF_WRITE_SERMON'|translate}} (Türkçe)</span>
                                                        </a>

                                                        <a *ngIf="specialSermon?.content?.folderPathFr"
                                                            href="{{photoUrl+specialSermon?.content?.folderPathFr}}"
                                                            download class="dropdown-item d-flex align-items-center">

                                                            <span>{{'PDF_WRITE_SERMON'|translate}} (Français)</span>
                                                        </a>
                                                        <a *ngIf="specialSermon?.content?.soundPath"
                                                            href="{{photoUrl+specialSermon?.content?.soundPath}}"
                                                            download class="dropdown-item d-flex align-items-center">
                                                            <span>{{'MP3_SOUND_SERMON'|translate}} (Türkçe)</span>
                                                        </a>


                                                        <a *ngIf="specialSermon?.content?.soundPathFr"
                                                            href="{{photoUrl+specialSermon?.content?.soundPathFr}}"
                                                            download class="dropdown-item d-flex align-items-center">
                                                            <span>{{'MP3_SOUND_SERMON'|translate}} (Français)</span>
                                                        </a>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="educationStatu=='Aktif'">
                <app-homepage-education></app-homepage-education>
            </ng-container>

            <ng-container *ngIf="eventStatu=='Aktif'">
                <app-homepage-events></app-homepage-events>
            </ng-container>

            <app-homepage-publication></app-homepage-publication>


        </div>

       <app-homepage-memories></app-homepage-memories>
    </div>

</div>