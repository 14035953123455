import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../../../services/content.service';

@Component({
  selector: 'app-news-info-page',
  templateUrl: './news-info-page.component.html',
  styleUrls: ['./news-info-page.component.scss']
})
export class NewsInfoPageComponent {
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.baseServerUrl
  news: any;
  lastData: any[] = [];
  newsId = 10;
  logoUrl = environment.logoUrl
  public href = window.location.href;
  constructor(private activeRoute: ActivatedRoute, private contentService: ContentService, private router: Router) { }



  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data: { news: any }) => {
        if (data.news) {
          
          this.news = data?.news?.data;
          this.news = data?.news?.data;
          const editorContainer = document.getElementById("dynamic-value");
          const shadow = editorContainer.attachShadow({ mode: "closed" });

          const content = document.createElement("div");
          content.innerHTML = this.news.description;

          shadow.appendChild(content);
          this.getAll();

        }
      });

  }


  navigate(isShare, id) {
    if (isShare == true) {
      this.router.navigate(['/haber-detay', id, 'true']).then(() => {
        window.location.reload();
      });
    }else{
      this.router.navigate(['/haber-detay', id, 'false']).then(() => {
        window.location.reload();
      });
    }


  }

  getAll() {
    const url = `GetAllContentResource`
    this.contentService.getBaseContentResourceData(url, this.news?.contentTypeId, '', '1.01.0001+00:00:00,', 0, this.newsId).subscribe((data: any) => {
      this.lastData = data.filter(lastData => lastData.isActive === true && lastData.id != this.news?.id).slice(0, 4);

    })
  }


  missionSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }


}
