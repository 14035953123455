import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent {
  @Input() menu: any;

  open: boolean = false;
  trigger(menu: { open: boolean; }) {
    menu.open = !menu.open;

  }
}
