<div class="container publish" *ngIf="publication.length>0">
    <h4>{{'PUBLICATION'|translate}}</h4>
    <div class="blog-slides">
        <owl-carousel-o [options]="carouselPublishSlide">
            <ng-template carouselSlide *ngFor="let item of publication">
                <app-publish-card [item]="item"></app-publish-card>
            </ng-template>

        </owl-carousel-o>
        <a class="router-button" [routerLink]="['/yayinlar']">
            <button class="view-all-contents-button">
                {{'SEE_ALL' | translate}}
            </button>
        </a>

    </div>
</div>