<app-banner [data]="{content: 'PUBLICATION' | translate, title: 'PUBLICATION' | translate , link:'/yayinlar'}"></app-banner>


<div class="container events education pt-70 pb-100">
    <div class="container publish mtb-30" *ngFor="let category of publication">
        <div class="blog-slides">
            <a
                [routerLink]="category.isShare === true ? ['/yayinlar',category.name,category.id,'true'] : ['/yayinlar',category.name,category.id,'false']">
                <h4>{{category.isShare==true ? category.name + ' (ditibfrance.fr)' : category.name}}
                    <svg xmlns="http://www.w3.org/2000/svg" stroke="#ED2939" stroke-width="3%" width="20" height="20"
                        viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_1_1828)">
                            <path
                                d="M16 10.061C16 9.6792 15.854 9.35352 15.5508 9.06152L6.79102 0.492675C6.55518 0.245605 6.24072 0.122069 5.88135 0.122069C5.15137 0.122069 4.57861 0.683593 4.57861 1.4248C4.57861 1.78418 4.72461 2.10986 4.97168 2.35693L12.8667 10.061L4.97168 17.7651C4.72461 18.0234 4.57861 18.3491 4.57861 18.7085C4.57861 19.4385 5.15137 20 5.88135 20C6.24072 20 6.55518 19.8765 6.79102 19.6294L15.5508 11.0605C15.854 10.7686 15.9888 10.4429 16 10.061Z"
                                fill="#ED2939" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_1828">
                                <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)" />
                            </clipPath>
                        </defs>
                    </svg>
                </h4>
            </a>
            <owl-carousel-o [options]="carouselPublishSlide">
                <ng-template carouselSlide *ngFor="let item of category?.publications">
                    <ng-container *ngIf="category?.isActive==true">
                        <app-publish-card [item]="item"></app-publish-card>
                    </ng-container>

                </ng-template>

            </owl-carousel-o>
        </div>
    </div>

</div>