import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { EventService } from '../../../services/event.service';


@Injectable()
export class EventsResolver implements Resolve<any> {
    constructor(private eventService: EventService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const code = route.paramMap.get('code');
        const base = route.paramMap.get('base');

        return this.eventService.getEventById(code, base) as Observable<any>;
    }

}