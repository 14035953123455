
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Publication } from 'src/app/models/publication';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';



@Injectable({
    providedIn: 'root'
})
export class PublicationService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl

    constructor(private http: HttpClient, private baseService: BaseService) { }


    getPublicationById(id, base): Observable<Publication> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetPublicationById?Id=${id}`;
        return this.http.get<Publication>(url);
    }

    // ~~~~~~~~~~~~~~~~~~ Publication General Services ~~~~~~~~~~~~~~~~~~~~~ //

    getBaseHomePublication(): Observable<Publication[]> {
        const url = `GetAllPublication?MainPage=true`
        return this.baseService.baseData(url);

    }

    getBasePublicationResourceData(base, publicationType, searchQuery, pageSize, skip): Observable<Publication[]> {
        const url = `GetAllPublication?PublicationTypeId=${publicationType}&SearchQuery=${searchQuery}&PageSize=${pageSize}&Skip=${skip}`;

        if (base === null) {
            return this.baseService.baseData(url);
        }
        else {
            return this.http.get<Publication[]>((base == 'false' ? this.path : (this.basePath)) + url);
        }


    }

    getBasePublicationType(): Observable<Publication[]> {
        const url = `GetAllPublicationType`

        const dataList$ = this.http.get(this.path + url);
        const dataBaseList$ = this.http.get(this.basePath + url);

        return forkJoin({
            baseList: dataBaseList$.pipe(
                map((data: any) => data?.data?.filter(item => item.isActive === true && item.isDeleted === false))
            ),
            list: dataList$.pipe(
                map((data: any) => data?.data?.filter(item => item.isActive === true && item.isDeleted === false))
            )
        }).pipe(
            map((data: { baseList: any[], list: any[] }) => {
                const baseList = data.baseList;
                const dataList = data.list;
                let sharedPublication = []

                sharedPublication = baseList.filter(item => {
                    return item.publications.some(pub => pub.isShare === true);
                });

                sharedPublication.forEach((publication) => {
                    publication.publications = publication.publications.filter(element => element.isShare === true);
                    publication.publications.forEach(element => {
                        element.isShare = true;
                    });
                    publication.isShare = true
                });


                const mergedDataArray = [...baseList, ...dataList];
                return mergedDataArray.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());

            })
        );
    }

}