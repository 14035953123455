
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class EventService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl

    constructor(private http: HttpClient) {}


    getEventById(code, base): Observable<Event> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetEventById?Id=${code}`;
        return this.http.get<Event>(url);
    }
}