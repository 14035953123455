<div class="owl-banner">
        <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let item of smallSlider">
                        <a [href]="item?.frontPageId ? '/slider-detay/'+ item.frontPageId : (item?.url ? item?.url : '' )"
                                [target]="!item?.url.charAt('/') && item?.url ?'_blank' : '' ">
                                <div class="div-partner-area">
                                        <div class="mini-slider">
                                                <img  [src]="photoUrl+item?.image" alt="">

                                                <div class="link">
                                                        {{item?.title }}
                                                </div>
                                        </div>
                                </div>
                        </a>
                </ng-template>
        </owl-carousel-o>
</div>