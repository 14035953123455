import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService } from 'src/app/services/appsetting.service';

@Component({
  selector: 'app-left-box',
  templateUrl: './left-box.component.html',
  styleUrls: ['./left-box.component.scss']
})
export class LeftBoxComponent implements OnInit {
  url: any
  isActiveLink: boolean = false;

  constructor(private appSettingService: AppSettingService, private router: Router) { }

  ngOnInit(): void {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.url = data.find(d => d.key === 'genelbagislink').value || '';
      const normalizedUrl = this.url.normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim().toLowerCase();
      this.isActiveLink = normalizedUrl === 'pasif';
    })
  }


  navigate() {
    if (this.url.startsWith('http')) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = this.url
      a.click();
    }
    else {
      this.router.navigate([this.url]).then(() => {
        window.location.reload();
      });

    }

  }

}
