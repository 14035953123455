<app-banner [data]="{content: 'SALMONS' | translate, title: 'SALMONS' | translate}"></app-banner>


<div class="container salmon pt-70 pb-100">
    <div class="title-search">
        <div class=" row justify-content-center">
            <div class="custom-select-box ">
                <div class="dropdown language-switcher d-inline-block " [class.active]="classApplied2">
                    <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                        <span>{{'SEARCH_BY_DATE'|translate}} <i class='bx bx-chevron-down'></i></span>
                    </button>
                    <div class="dropdown-menu">
                        <div class="dropdown-header">
                            <select class="form-select" [(ngModel)]="selectedYear">
                                <option [value]="item" *ngFor="let item of yearArray">{{item}}</option>
                            </select>
                        </div>

                        <div class="container" [(ngModel)]="selectedMonth">
                            <div class="row">
                                <div class="col-md-4 col-sm-6 col-sm-12 months" *ngFor="let month of monthArray">
                                    <button (click)="filter(month.id)"
                                        [ngClass]="{'active focus': month === selectedMonth }">{{month.name}}</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="blog-slides container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-sm-12" *ngFor="let item of list">
                <div class="single-courses-box mb-30">
                    <div class="sermon-header">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50" height="50"
                            viewBox="0 0 25 25" style="enable-background:new 0 0 25 25;" xml:space="preserve">
                            <style type="text/css">
                                .st0 {
                                    fill: none;
                                }

                                .st1 {
                                    fill-rule: evenodd;
                                    clip-rule: evenodd;
                                    fill: #ED2939;
                                }
                            </style>
                            <g transform="matrix(1,0,0,1,-336,-341)">
                                <g transform="matrix(2,0,0,2,336,341)">
                                    <rect class="st0" width="12.5" height="12.5" />
                                    <g transform="matrix(0.5,0,0,0.5,-418.5,-170.5)">
                                        <path class="st1" d="M859.7,362.1v-5.9c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.2l-3.1-1.1v-0.7c0-0.2-0.2-0.4-0.4-0.4
                                   s-0.4,0.2-0.4,0.4v0.4l-3.1-1.1v-0.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.3l-2.7-0.9v-0.5c0-0.2-0.2-0.4-0.4-0.4
                                   s-0.4,0.2-0.4,0.4v2l-1.2-0.4v-1.3c0-0.2-0.2-0.4-0.4-0.4h-0.4v-3.9h0.4c0.2,0,0.4-0.2,0.4-0.4v-1.6c0-0.2-0.2-0.4-0.4-0.4h-0.4
                                   c0-0.9-0.3-1.5-0.6-2c-0.4-0.6-1.1-1-1.9-1.4c-0.1-0.1-0.2-0.1-0.3,0c-0.8,0.4-1.5,0.9-1.9,1.4c-0.4,0.5-0.6,1.2-0.6,2h-0.4
                                   c-0.2,0-0.4,0.2-0.4,0.4v1.6c0,0.2,0.2,0.4,0.4,0.4h0.4v3.9h-0.4c-0.2,0-0.4,0.2-0.4,0.4v9.4H839c-0.2,0-0.4,0.2-0.4,0.4v1.6
                                   c0,0.2,0.2,0.4,0.4,0.4H860c0.2,0,0.4-0.2,0.4-0.4v-1.6c0-0.2-0.2-0.4-0.4-0.4H859.7z M839.3,362.9h20.3v0.8h-20.3V362.9z
                                    M845.9,354.7h-5.8v7.4h18.8v-3.2L845.9,354.7z M840.9,361.3c0,0.2,0.2,0.4,0.4,0.4h3.9c0.2,0,0.4-0.2,0.4-0.4V357
                                   c0-0.2-0.1-0.3-0.3-0.4c-0.6-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.5-0.6-1-0.7c-0.1,0-0.2,0-0.2,0c-0.6,0.2-0.8,0.5-1,0.7
                                   c-0.2,0.3-0.3,0.6-0.9,0.8c-0.2,0.1-0.3,0.2-0.3,0.4V361.3z M846.9,355.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3v5.5
                                   c0,0.2,0.2,0.4,0.4,0.4h10.9c0.2,0,0.4-0.2,0.4-0.4v-2c0-0.2-0.1-0.3-0.3-0.4L846.9,355.5z M841.7,360.9v-3.6
                                   c0.4-0.2,0.6-0.4,0.8-0.6c0.2-0.3,0.3-0.6,0.8-0.8c0.5,0.2,0.6,0.5,0.8,0.8c0.2,0.2,0.4,0.5,0.8,0.6v3.6H841.7z M847.2,356.4
                                   l10.2,3.3v1.3h-10.2V356.4z M855.8,357C855.8,357,855.8,357,855.8,357L855.8,357l3.1,1v-0.7l-3.1-1.1V357z M851.8,355.8l3.1,1
                                   v-0.8l-3.1-1.1V355.8z M848.3,354.6l2.7,0.9v-0.9l-2.7-0.9V354.6z M840.1,353.1h5.5v0.8h-5.5V353.1z M843.2,348.4h-0.8v0.4
                                   c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h-0.4v2.3h3.9V350h-0.4c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V348.4z
                                    M844.8,349.2v-0.8H844v0.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1H844.8z M841.7,348.4h-0.8v0.8h0.4c0.1,0,0.2,0,0.3-0.1
                                   c0.1-0.1,0.1-0.2,0.1-0.3V348.4z M840.1,346.9h5.5v0.8h-5.5V346.9z M844.8,346.1c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.5-0.8-0.8-1.4-1.1
                                   c-0.6,0.3-1.1,0.7-1.4,1.1c-0.3,0.4-0.5,0.9-0.5,1.6H844.8z" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <!-- {{item?.date}} -->
                    </div>
                    <div class="sermon-subject">
                        {{item?.title}}
                    </div>
                    <div class="type-info-area">
                        <div class="row">
                            <p>{{'DOWNLOAD'|translate}}</p>
                            <!-- <div class="type-info-box" *ngIf="item.soundPath!='' ">
                                <a href="{{photoUrl+item.soundPath}}" target="_blank" download>
                                    MP3
                                </a>
                            </div> -->
                            <div class="type-info-box social-btn-mp3" *ngIf="item.soundPath || item.soundPathFr ">
                                MP3
                                <ul>
                                    <li>
                                        <a target="_blank" *ngIf="item?.soundPath"
                                            href="{{photoUrl+item.soundPath}}" download>
                                            Türkçe
                                        </a>
                                        <a target="_blank" *ngIf="item?.soundPathFr"
                                            href="{{photoUrl+item.soundPathFr}}" download>
                                            Français
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="">
                                <a href="{{photoUrl+item.folderPath}}" download>
                                    PDF
                                </a>
                            </div> -->
                            <div class="type-info-box social-btn" *ngIf="item.folderPath || item.folderPathFr">
                                PDF
                                <ul>
                                    <li>
                                        <a target="_blank" *ngIf=" item?.folderPath"
                                            href="{{photoUrl+item.folderPath}}" download>
                                            Türkçe 
                                        </a>

                                        <a *ngIf="item?.folderPathFr" target="_blank"
                                            href="{{photoUrl+item.folderPathFr}}" download>
                                            Français 
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="totalCount === 0">
        <div class="row">
            <div class="no-data col-sm-12 ml-4 mt-3">
                <label class="font-weight-bold"> <img src="assets/img/no-data.png" alt=""> {{'NO_DATA_FOUND' | translate}}</label>
            </div>
        </div>
    </ng-container>
    <mat-paginator [length]="totalCount" [pageSize]="pageSize" [hidePageSize]="'true'" (page)="onPageChange($event)"
        [showFirstLastButtons]="true"></mat-paginator>
</div>