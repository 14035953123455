<div class="blog-area  pb-70" *ngIf="memories?.length>0">
    <div class="container">
        <div class="section-title text-start">
            <h4>{{'MEMORIES'|translate}}</h4>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="memoriesSlides">
                <ng-template carouselSlide *ngFor="let item of memories">
                    <app-memories-card [item]="item"></app-memories-card>
                </ng-template>
            </owl-carousel-o>

            <a class="router-button" routerLink="/anilar">
                <button class="view-all-contents-button blue">
                    {{'SEE_ALL' | translate}}
                </button>
            </a>

        </div>
    </div>
</div>