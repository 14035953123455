import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PublicationService } from 'src/app/services/publication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publish-type-page',
  templateUrl: './publish-type-page.component.html',
  styleUrls: ['./publish-type-page.component.scss']
})
export class PublishTypePageComponent implements OnInit {
  id: number;
  name: string;
  skip: number = 0;
  pageSize: number = 12;
  totalCount: number = 0;
  search: string = "";
  publication: any
  base: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  private searchSubject = new Subject<any>();

  constructor( private router: ActivatedRoute , private publicationService: PublicationService) {
    this.searchSubject
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.skip = 0;
        this.pageSize = 12;
        this.totalCount = 0;
        this.paginator.pageIndex = 0;
        this.getAll();

      });
  }

  ngOnInit(): void {
    this.router.params.subscribe((params: { name: string, id: number, base: string }) => {
      this.id = params.id;
      this.name = params.name;
      this.base = params.base;
    })
    this.getAll()
  }

  getAll() {
    this.publicationService.getBasePublicationResourceData(this.base, this.id, this.search, this.pageSize, this.skip).subscribe((data: any) => {
      if (this.base === 'true') { this.publication = data.data.filter(data => data.isShare === true); this.totalCount = this.publication.length; }
      else {
        this.publication = data.data
        this.totalCount = data.totalCount;
      }
    })
  }

  onPageChange(e) {
    this.skip = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAll();

  }

  onChange(e) {
    this.search = e.target.value ? e.target.value : ''
    this.searchSubject.next(this.search);
  }
}
