import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LightgalleryModule } from 'lightgallery/angular';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventCardComponent } from './components/pages/events-page/event-card/event-card.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { EducationResolver } from './components/pages/about-page-two/education.resolver';
import { AdvisorPageComponent } from './components/pages/advisor-page/advisor-page.component';
import { AssociationsComponent } from './components/pages/associations/associations.component';
import { BannerComponent } from './components/pages/banner/banner.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { EducationPageComponent } from './components/pages/education-page/education-page.component';
import { HomepageEducationComponent } from './components/pages/education-page/homepage-education/homepage-education.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { EventsDetailComponent } from './components/pages/events-page/events-detail/events-detail.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EventsResolver } from './components/pages/events-page/events.resolver';
import { HomepageEventsComponent } from './components/pages/events-page/homepage-events/homepage-events.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';
import { HomeComponent } from './components/pages/home/home.component';
import { MemoriesCardComponent } from './components/pages/memories/memories-card/memories-card.component';
import { MemoriesComponent } from './components/pages/memories/memories.component';
import { HomepageNewsComponent } from './components/pages/news/homepage-news/homepage-news.component';
import { NewsInfoPageComponent } from './components/pages/news/news-info-page/news-info-page.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';
import { OnlineDonateComponent } from './components/pages/online-donate/online-donate.component';
import { HomepagePublicationComponent } from './components/pages/publish-page/homepage-publication/homepage-publication.component';
import { PublishInfoPageComponent } from './components/pages/publish-page/publish-info-page/publish-info-page.component';
import { PublishPageComponent } from './components/pages/publish-page/publish-page.component';
import { PublishTypePageComponent } from './components/pages/publish-page/publish-type-page/publish-type-page.component';
import { PublishResolver } from './components/pages/publish-page/publish.resolver';
import { SalmonPageComponent } from './components/pages/sermon-page/salmon-page.component';
import { TabsDetailComponent } from './components/pages/tabs-detail/tabs-detail.component';
import { TabsDetailResolver } from './components/pages/tabs-detail/tabs-detail.resolver';
import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';
import { CoreModule } from './core/core.module';
import { createTranslateLoader } from './translater-loader';
import { EducationCardComponent } from './components/pages/education-page/education-card/education-card.component';
import { NewsCardComponent } from './components/pages/news/news-card/news-card.component';
import { PublishCardComponent } from './components/pages/publish-page/publish-card/publish-card.component';
import { HomepageMemoriesComponent } from './components/pages/memories/homepage-memories/homepage-memories.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutPageOneComponent,
    AboutPageTwoComponent,
    ErrorPageComponent,
    ContactPageComponent,
    HomePageEightComponent,
    HomePageElevenComponent,
    NewsComponent,
    WebPagesComponent,
    HomeComponent,
    EventsPageComponent,
    EducationPageComponent,
    PublishPageComponent,
    PublishInfoPageComponent,
    SalmonPageComponent,
    NewsInfoPageComponent,
    AssociationsComponent,
    AdvisorPageComponent,
    PublishTypePageComponent,
    TabsDetailComponent,
    MemoriesComponent,
    EventsDetailComponent,
    OnlineDonateComponent,
    BannerComponent,
    HomepageNewsComponent,
    HomepageEducationComponent,
    HomepageEventsComponent,
    HomepagePublicationComponent,
    MemoriesCardComponent,
    EventCardComponent,
    EducationCardComponent,
    NewsCardComponent,
    PublishCardComponent,
    HomepageMemoriesComponent

  ],
  imports: [
    MatTabsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    LightgalleryModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatPaginatorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [NewsResolver, WebPagesResolver, PublishResolver, EducationResolver, TabsDetailResolver, EventsResolver,
    { provide: MAT_DATE_LOCALE, useValue: 'tr' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
