import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { PageService } from '../../../services/page.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WebPagesResolver implements Resolve<any> {
    constructor(private pageService: PageService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {        
        const id = route.paramMap.get('id');
        const base = route.paramMap.get('base') || 'false';
        return this.pageService.getContentPage(id, base).pipe(
            map(data => ({
                ...data.data,
                base

            }))

        );


    }
}