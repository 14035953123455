<a [routerLink]="['/ani-detay',item.id]">
    <div class="single-blog-post mb-30">
        <div class="post-image">
            <img [src]="item?.thumbnail ? photoUrl+item?.thumbnail : 
            logoUrl" alt="image" style="height: 100%;">

        </div>
        <div class="post-content">
            <ul class="post-meta">
                <!-- <li><a routerLink="/">August 30, 2019</a></li> -->
            </ul>
            <h3>
                <a class="d-inline-block">
                    <p class="line-clamp-two" [innerHTML]="item?.title"></p>
                </a>
            </h3>

            <button class="view-all-contents-button blue">
                {{'SEE_MORE' | translate}}
            </button>
        </div>
    </div>
</a>