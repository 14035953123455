
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page';



@Injectable({
    providedIn: 'root'
})
export class PageService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl;

    constructor(private http: HttpClient) { }

    getContentPage(id, base): Observable<Page> {
        const url = (base == 'false' ? this.path : this.basePath) + `GetContentPageById?Id=${id}`;
        return this.http.get<Page>(url);

    }
}