import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EducationService } from 'src/app/services/education.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-events',
  templateUrl: './homepage-events.component.html',
  styleUrls: ['./homepage-events.component.scss']
})
export class HomepageEventsComponent {
  photoUrl: string = environment.serverUrl
  basePhotoUrl: string = environment.baseServerUrl
  event: any[] = [];

  constructor(private educationService: EducationService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    const url = `GetAlleventsResource`
    this.educationService.getBaseData(url).subscribe((data: any) => {
      this.event = data;
    })

  }

  carouselEventsSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 5
      }
    }
  }
}
