import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PublicationService } from 'src/app/services/publication.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-publish-info-page',
  templateUrl: './publish-info-page.component.html',
  styleUrls: ['./publish-info-page.component.scss']
})
export class PublishInfoPageComponent implements OnInit {
  photoUrl = environment.serverUrl
  basePhotoUrl = environment.baseServerUrl
  publish: any;
  publications: any[]
  logoUrl = environment.logoUrl
  constructor(private activeRoute: ActivatedRoute, private publicationService: PublicationService) { }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { publish: any }) => {
        if (data.publish) {
          this.publish = data?.publish?.data;
        }
      });

    this.publicationService.getBasePublicationResourceData(null, this.publish.publicationTypeId, '', 10, 0).subscribe((data: any) => {
      this.publications = data.filter(data => data?.id !== this.publish?.id);
    })
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }


  detailsImageSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  }

  carouselPublishSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 7
      }
    }
  }


}
