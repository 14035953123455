import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// ----------- META ETİKETLERİ ------------- //
function setMetaTags() {
  // FAVICON
  const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = environment.logoUrl;
  document.getElementsByTagName('head')[0].appendChild(link);

  // IMAGE
  const metaOgImage: HTMLMetaElement = document.querySelector("meta[property='og:image']") || document.createElement('meta');
  metaOgImage.setAttribute('property', 'og:image');
  metaOgImage.content = environment.logoUrl;
  document.getElementsByTagName('head')[0].appendChild(metaOgImage);

  // TITLE
  const metaOgTitle: HTMLMetaElement = document.querySelector("meta[property='og:title']") || document.createElement('meta');
  metaOgTitle.setAttribute('property', 'og:title');
  metaOgTitle.content = environment.title;
  document.getElementsByTagName('head')[0].appendChild(metaOgTitle);

  // DESCRIPTION
  const metaOgDescription: HTMLMetaElement = document.querySelector("meta[property='og:description']") || document.createElement('meta');
  metaOgDescription.setAttribute('property', 'og:description');
  metaOgDescription.content = environment.description;
  document.getElementsByTagName('head')[0].appendChild(metaOgDescription);

  // Set document title
  document.title = environment.title;
}

setMetaTags();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
