import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContentService } from '../../../services/content.service';


@Component({
  selector: 'app-memories',
  templateUrl: './memories.component.html',
  styleUrls: ['./memories.component.scss']
})
export class MemoriesComponent implements OnInit {
  creationDate: any = '1.01.0001+00:00:00';
  skip: number = 0;
  pageSize: number = 12;
  totalCount: number = 0;
  search: string = "";
  list: any;
  photoUrl: string = environment.serverUrl;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private searchSubject = new Subject<any>();

  constructor(private contentService: ContentService) {
    this.searchSubject
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.skip = 0;
        this.pageSize = 12;
        this.totalCount = 0;
        this.paginator.pageIndex = 0;
        this.getAll();

      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.contentService.getAllContentResource('false', 28, this.search, this.creationDate, this.skip, this.pageSize).subscribe((data: any) => {
      this.list = data.data;
      this.totalCount = data.totalCount;

    })
  }



  onPageChange(e) {
    this.skip = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAll();

  }

  onChange(e) {
    this.search = e.target.value ? e.target.value : ''
    this.searchSubject.next(this.search);
  }

}
