<app-banner [data]="{content: 'CONTACT' | translate, title: 'CONTACT' | translate}"></app-banner>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <h1 style="text-align: center;"> DİTİB - FRANSA </h1> -->
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:{{email}}">{{email}}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{'ADDRESS'|translate}}</h3>
                    <p><a rel="noopener" href="https://www.google.com/maps/place/{{ address }}"
                            target="_blank">{{address}}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'PHONE'|translate}}</h3>
                    <p><a href="tel:{{ telephone }}">{{ telephone }}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'FUNERAL_FUND'|translate}}</h3>
                    <p *ngIf="funeralFundMail"><a href="mailto:{{funeralFundMail}}">{{funeralFundMail}}</a></p>
                    <p><a href="tel:{{ telephone2 }}">{{ telephone2 }} </a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.4405360508763!2d2.4244495999999995!3d48.8688779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66d6e1e3ff90b%3A0xdaa2fdec2bf89777!2s58%20Rue%20L%C3%A9nine%2C%2093170%20Bagnolet%2C%20Fransa!5e0!3m2!1str!2str!4v1711358884476!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<!-- <div id="social-platforms">
    <h1>Strazburg Din Hizmetleri Ataşeliği İletişim Bilgileri</h1>
    <a class="btn btn-icon btn-linkedin" href="#"><i class="fa fa-phone"></i><span> +33 3 90 20 24 85</span></a>
    <a class="btn btn-icon btn-facebook" href="www.Facebook.com/DitibStrasbourgfr"><i
            class="fa fa-facebook"></i><span>Facebook</span></a>
    <a class="btn btn-icon btn-twitter" href="www.twitter.com/DİTİBStrasbourg"><i
            class="fa fa-twitter"></i><span>Twitter</span></a>
    <a class="btn btn-icon btn-googleplus" href="www.ditibstrasbourg.fr"><i
            class="fa-sharp fa-solid fa-globe"></i><span>Web</span></a>
    <a class="btn btn-icon btn-pinterest" href="www.YouTube.com/DİTİBStrasbourg"><i
            class="fa fa-youtube"></i><span>Youtube</span></a>
    <a class="btn btn-icon btn-linkedin" href="#"><i class="fa fa-instagram"></i><span>Instagram</span></a>

</div> -->
<!-- <div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="login-box">
            <h1 style="text-align: center;">Strazburg Din Hizmetleri Ataşeliği İletişim Bilgileri</h1>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect"><i
                                class="fa-sharp fa-solid fa-phone-volume fa-xl"></i><span> +33 3 90 20 24 85</span></a>
                    </div>
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect">
                            <i class="fa-brands fa-facebook fa-xl"></i>
                            <span>www.Facebook.com/DitibStrasbourgfr</span></a>

                    </div>
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect"> <i
                                class="fa-sharp fa-solid fa-globe fa-xl"></i><span>www.ditibstrasbourg.fr</span></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect">
                            <i class="fa-brands fa-youtube fa-xl"></i> <span>www.YouTube.com/DİTİBStrasbourg</span></a>
                    </div>
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect">
                            <i class="fa-brands fa-instagram fa-xl"></i>
                            <span>www.instagram.com/DİTİBStrasbourg</span></a>
                    </div>
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect">
                            <i class="fa-brands fa-square-x-twitter"></i>
                            <span>www.twitter.com/DİTİBStrasbourg</span></a>
                    </div>


                </div>
                <div class="row">
                    <div class="col-sm-4 mb-4">
                        <a href="#" class="social-button" id="google-connect">
                            <i class="fa-sharp fa-solid fa-envelope fa-lg"></i>
                            <span>strazburg.ataselik@diyanet.go</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="margin-top: 10px;">İletişim Formu</h2>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel"
                                class="form-control" id="name" placeholder="İsim giriniz">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">İsim zorunludur.</div>
                                <div *ngIf="name.errors.minlength">İsim en az {{
                                    name.errors.minlength.requiredLength }} karakterdir.</div>
                                <div *ngIf="name.errors.maxlength">İsin en fazla 50 karakterdir.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control"
                                id="email" placeholder="E-Posta giriniz">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">E-Posta zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control"
                                id="number" placeholder="Telefon">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Telefon zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control"
                                id="subject" placeholder="Konu giriniz">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Zorunlu alan.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5"
                                class="form-control" placeholder="Mesajınız..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Zorunlu alan.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div> -->